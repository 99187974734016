@use "./common" as *;

.header-advertising {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    z-index: 1;

    #ad-topBanner1,
    #ad-midbanner {
        display: flex;
        align-items: center;

        @include tablet {
            height: auto;
        }
    }

    &.h-60px {
        #ad-topBanner1,
        #ad-midbanner {
            height: 90px;
        }
    }

    @media screen and (max-width: 1024px) {
        width: 100%;
    }

    @include tablet {
        position: fixed;
        bottom: 0;
        padding: 5px 0;
        z-index: 11;
        height: 90px;

        &.overlay {
            position: fixed;
        }
    }

    @include mobile {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:empty {
        /* Handle Mobile : (Portrait and Landscape) */
        @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
            display: block;
        }

        @media screen and (max-width: 767px) {
            display: block;
        }
    }
}

header {
    .region-header {
        background: $aubergine-color;
        align-items: center;
        display: flex;
        flex-direction: column;
        z-index: 500;
        padding: rem(25px) 0 rem(5px) 0;

        @include tablet {
            padding: 0;
            border-bottom: unset;
        }

        #block-tsn-branding {
            position: relative;
            line-height: inherit;
            color: $tsn-branding-color;
            text-decoration: none;
            cursor: pointer;
            display: inline-block;

            .site-logo {
                display: block;
                position: relative;
                width: rem(300px);
                height: rem(38px);
                filter: invert(1) contrast(200%);

                @include footer-tsn-logo;
            }

            @media screen and (max-width: 768px) {
                display: none;
            }
        }
    }
}

.nba-ad-strip {
    width: 980px;
    height: 30px;
    margin: 20px auto 0;

    &:empty {
        display: none;
    }

    @include mobile {
        width: 300px;
        height: 100px;
    }
}

.favicon {
    @include favicon-ico;
}

.layout-container:not(:has(.header-advertising)) {
    .tsn-primary-menu {
        @include tablet {
            top: 0;
        }
    }

    @include tablet {
        header {
            .region-header {
                margin-top: 0;
            }
        }
    }
}

/* START: NBA Bet Header */
.nbabet-header-container {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 60px;
    display: flex;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;

    .left-banner {
        @include nba-left-banner;
    }

    .right-banner {
        @include nba-right-banner;
    }

    .left-banner,
    .right-banner {
        margin: 0;
        padding: 0;
        width: 100vw;
        height: 60px;
        background-color: #ffffff;
        background-size: cover;

        @include mobile {
            width: 105px;
        }
    }

    .nbabet-content {
        margin: 0 8px;
        padding: 0;
        width: 100%;
        height: 37px;
        min-width: 300px;
        max-width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;

        @include tablet {
            height: 28px;
        }

        @include mobile {
            margin: 0;
            width: 100%;
            min-width: 180px;
        }

        .nbabet-logo {
            margin: 0;
            padding: 0;
            width: 95px;
            height: 37px;

            @include nba-bet-logo;

            @include tablet {
                width: 72px;
                height: 28px;
            }
        }

        .partner-logo {
            margin: 0;
            padding: 0;
            height: 37px;
            display: block;

            @include tablet {
                height: 28px;
            }
        }

        .presentedby {
            margin: 0 8px;
            padding: 0;
            font-family: Roboto-Condensed, sans-serif;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.22px;
            text-align: left;
            color: #1f1f1f;
            text-wrap: nowrap;
            display: block;

            @include tablet {
                font-size: rem(14px);
            }
        }
    }

    &.au-edition .nbabet-content {
        max-width: 330px;

        .partner-logo {
            @include sportsbet-logo;

            height: 37px;
            width: 100px;
        }

        @include tablet {
            height: auto;
            flex-direction: column;
            min-width: 210px;

            .partner-logo {
                width: 126px;
                height: 30px;
            }
        }
    }

    &.ca-edition {
        .partner-content-container {
            .partner-logo {
                width: 105.5px;
                height: 30px;

                @include tablet {
                    width: 77px;
                    height: 20px;
                }
            }
        }

        .nbabet-content {
            @include mobile {
                min-width: 250px;
            }

            .partner-logo {
                @include betmgm-logo;

                width: 137px;

                @include tablet {
                    width: 98.5px;
                }
            }
        }
    }

    &.es-edition .nbabet-content {
        @include mobile {
            min-width: 200px;
        }

        .partner-logo {
            @include dazn-logo;

            width: 78px;
            height: 38px;

            @include tablet {
                width: 56.5px;
                height: 30px;
            }
        }
    }
}

/* END: NBA Bet Header */
