@use "./common" as *;

footer {
    padding: rem(50px) rem(15px) rem(20px);
    width: 100%;
    text-align: center;

    #block-sitebranding {
        display: flex;
        justify-content: center;
    }

    .site-logo {
        width: rem(360px);
        height: rem(50px);

        @include footer-tsn-logo;

        @include mobile {
            width: rem(225px);
            height: rem(32px);
        }
    }

    .tsn-footer-social-media-container {
        margin: rem(30px) 0 rem(20px);

        a {
            &:not(:last-of-type) {
                margin-right: rem(20px);

                @include mobile {
                    margin-right: rem(6px);
                }
            }

            .social-media-logo {
                display: inline-block;
                height: 40px;
                width: 40px;
                border-radius: 50%;

                &.facebook {
                    @include social-media-icon("facebook");

                    background-size: 30%;
                }

                &.twitter,
                &.X,
                &.x {
                    @include social-media-icon("twitter");
                }

                &.youtube {
                    @include social-media-icon("youtube");
                }

                &.instagram {
                    @include social-media-icon("instagram");
                }

                &.tiktok {
                    @include social-media-icon("tiktok");
                }

                &.kwai {
                    @include social-media-icon("kwai");
                }

                &.threads {
                    @include social-media-icon("threads");
                }
            }
        }

        @include tablet {
            margin: rem(24px) 0 rem(40px) 0;
        }
    }

    .footer-menu {
        display: flex;
        justify-content: center;
        margin-bottom: rem(20px);

        .menu-item {
            font-size: rem(13px);
            padding: 0;
            list-style: none;

            &:not(:last-of-type) {
                margin-right: rem(20px);
            }

            a {
                color: $aubergine-color;
                text-decoration: none;
                transition: color 0.3s linear;

                &:hover {
                    color: $blue-color;
                    cursor: pointer;
                }

                @include tablet {
                    color: $footer-text-color;
                }
            }

            @include tablet {
                margin: 0 0 rem(10px) 0 !important;
            }
        }

        @include tablet {
            flex-direction: column;
            align-items: center;
            margin-bottom: rem(40px);
        }
    }

    #block-copyrightblock {
        color: $aubergine-color;
        font-size: rem(10px);

        @include tablet {
            color: $footer-text-color;
        }
    }

    @include tablet {
        padding-bottom: rem(90px);
    }

    @include mobile {
        padding-bottom: rem(60px);
    }
}

html:lang(pt-br) {
    div#block-sitebranding > div.brazil-exclusive-logo {
        display: block;
    }
}

html:lang(ko-kr) {
    div#block-sitebranding > div.korea-exclusive-logo {
        display: block;
    }
}

html:not([lang="pt-br"]),
html:not([lang="ko-kr"]) {
    div#block-sitebranding > div.generic-logo {
        display: block;
    }
}

html:lang(pt-br),
html:lang(ko-kr), {
    div#block-sitebranding > div.generic-logo {
        display: none;
    }
}

div#block-sitebranding:has(.brazil-exclusive-logo) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 16px;

    .brazil-exclusive-logo {
        display: none;
    }

    .partnership-text {
        color: $aubergine-color;
        font-size: rem(13px);
    }

    .partnership-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: -8px;
        gap: 16px;

        a.sn-square-logo {
            width: 40px;
            height: 40px;
            display: block;
            background-position: center;
            background-size: contain;

            @include footer-tsn-logo-square;
        }

        a.nosso-brand-logo {
            width: 80px;
            height: 40px;
            display: block;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            filter: grayscale(1) contrast(4) invert(1);

            @include footer-nosso-futbol;
        }
    }
}

div#block-sitebranding:has(.korea-exclusive-logo) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 16px;

    .korea-exclusive-logo {
        display: none;
    }

    .partnership-text {
        color: $primary-menu-delimiter;
        font-size: rem(13px);
        font-weight: 400;

        a {
            text-decoration: none;
            color: $primary-menu-delimiter;
        }
    }

    .partnership-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: -8px;
        gap: 16px;

        a.sn-square-logo {
            width: 40px;
            height: 40px;
            display: block;
            background-position: center;
            background-size: contain;

            @include footer-tsn-logo-square;
        }

        a.tsn-korea-logo {
            width: 80px;
            height: 40px;
            display: block;

            @include tsn-korea-logo;
        }
    }
}

.placeholder {
    font-style: normal;
}

#gambling-awareness {
    gap: 8px;
    width: 100%;
    font-family: Roboto, sans-serif;
    color: #7d828e;
    font-size: 8px;
    line-height: 9.38px;
    text-transform: uppercase;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    margin: auto 0 60px;

    @media (min-width: 768px) {
        font-size: 10px;
        gap: 4px;
        padding: 32px 16px;
        margin-bottom: 0;
    }
}

.wide-page-layout #gambling-awareness {
    max-width: 1264px;
}

.narrow-page-layout #gambling-awareness {
    max-width: 1100px;
}

#gambling-awareness p {
    font-size: 8px;
    line-height: 9.38px;
    white-space: nowrap;
    margin: 0;

    @media (min-width: 768px) {
        font-size: 10px;
        line-height: normal;
    }
}

#gambling-awareness b,
#gambling-awareness b a {
    font-weight: normal;
    color: #1f1f1f;
}

#gambling-awareness a:hover,
#gambling-awareness .underline {
    text-decoration: underline;
}

#gambling-awareness.hide {
    display: none;
}

#gambling-awareness .hide {
    display: none;
}

#gambling-awareness #responsible-gaming-title {
    display: block;
}

#gambling-awareness #responsible-gaming-contact {
    display: block;
    line-height: 16px;

    @media (min-width: 768px) {
        line-height: normal;
        display: flex;
        flex-flow: row wrap;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
    }
}

#gambling-awareness #responsible-gaming-contact > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;

    @media (min-width: 768px) {
        row-gap: 4px;
        column-gap: 8px;
    }
}

#gambling-awareness #responsible-gaming-contact > div > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    margin-bottom: -16px;

    @media (min-width: 768px) {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 0;
    }
}

#gambling-awareness #responsible-gaming-contact span {
    display: none;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #e1e1e1;

    @media (min-width: 768px) {
        display: block;
    }
}

#gambling-awareness #responsible-gaming-contact .gambleware {
    margin-bottom: 8px;

    @media (min-width: 768px) {
        margin-bottom: 0;
    }
}

#gambling-awareness #responsible-gaming-contact .circular-divider {
    display: none;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-top: 1px;
    height: 8px;
    line-height: 14px;
    color: #7d828e;
    justify-content: center;
    background-color: #ffffff;

    @media (min-width: 768px) {
        display: flex;
        height: 10px;
    }
}

#gambling-awareness #gamingResponsibyLogos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    @media (min-width: 768px) {
        margin-top: 1px;
    }
}

#gambling-awareness #gamingResponsibyLogos #gaming-responsibly {
    width: 10px;
    height: 10px;
    font-size: 6.67px;
    line-height: normal;
    border: 1px solid #1f1f1f;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

    @media (min-width: 768px) {
        width: 24px;
        height: 24px;
        font-size: 10px;
    }
}

#gambling-awareness #gamingResponsibyLogos #uk-gaming-responsibly {
    width: 91.5px;
    height: 16px;
    background-size: contain;
    display: block;
    background-repeat: no-repeat;
    background-image: url("https://s3.us-west-2.amazonaws.com/zephr-templates.sportingnews.com/assets/gamble-responsibly-uk.jpeg");

    @media (min-width: 768px) {
        width: 137px;
        height: 24px;
    }
}

#gambling-awareness #gamingResponsibyLogos #juegoseguro {
    width: 16px;
    height: 16px;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("https://s3.us-west-2.amazonaws.com/zephr-templates.sportingnews.com/assets/age-gate-logo-juegoseguro.png");

    @media (min-width: 768px) {
        width: 24px;
        height: 24px;
    }
}

#gambling-awareness #gamingResponsibyLogos #jugarbieneskk {
    height: 16px;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    width: 45px;
    background-image: url("https://s3.us-west-2.amazonaws.com/zephr-templates.sportingnews.com/assets/age-gate-logo-jugarbieneskk.png");

    @media (min-width: 768px) {
        height: 24px;
        width: 67.5px;
    }
}

#gambling-awareness #gamingResponsibyLogos #jugarsegurokk {
    height: 16px;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    width: 88px;
    background-image: url("https://s3.us-west-2.amazonaws.com/zephr-templates.sportingnews.com/assets/age-gate-logo-jugarsegurokk.png");

    @media (min-width: 768px) {
        height: 24px;
        width: 132px;
    }
}

#gambling-awareness #gamingResponsibyLogos #ja {
    width: 16px;
    height: 16px;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("https://s3.us-west-2.amazonaws.com/zephr-templates.sportingnews.com/assets/ja.jpeg");

    @media (min-width: 768px) {
        width: 24px;
        height: 24px;
    }
}

#gambling-awareness #gamingResponsibyLogos #ibjr {
    width: 16px;
    height: 16px;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("https://s3.us-west-2.amazonaws.com/zephr-templates.sportingnews.com/assets/ibjr.png");

    @media (min-width: 768px) {
        width: 36px;
        height: 24px;
    }
}

#gambling-awareness #gamingResponsibyLogos #br-gaming-responsibly {
    width: 16px;
    height: 16px;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("https://s3.us-west-2.amazonaws.com/zephr-templates.sportingnews.com/assets/gamble-responsibly-18.png");

    @media (min-width: 768px) {
        width: 24px;
        height: 24px;
    }
}

#gambling-awareness #gamingResponsibyLogos #ca-gaming-responsibly {
    width: 16px;
    height: 16px;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("https://s3.us-west-2.amazonaws.com/zephr-templates.sportingnews.com/assets/gamble-responsibly-19.svg");

    @media (min-width: 768px) {
        width: 24px;
        height: 24px;
    }
}

#gambling-awareness #gamingResponsibyLogos #us-gaming-responsibly {
    width: 16px;
    height: 16px;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("https://s3.us-west-2.amazonaws.com/zephr-templates.sportingnews.com/assets/gamble-responsibly-21.svg");

    @media (min-width: 768px) {
        width: 24px;
        height: 24px;
    }
}

#gambling-awareness #geolocation-country {
    display: none;
}

#gambling-awareness .text-d4 {
    color: #1f1f1f;
}
