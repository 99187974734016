@use "./common" as *;

.tsn-primary-menu {
    &__top {
        background-color: $aubergine-color;
        transition: top 0.4s ease-out;
        padding: 0 10px;

        .tsn-primary-menu__container {
            position: relative;
            max-width: 1100px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            .mobile-menu-btn {
                position: absolute;
                top: 7px;
                left: 5px;
                display: none;
                width: 50px;
                height: 50px;
                border-radius: 50%;

                span {
                    position: absolute;
                    height: 2px;
                    width: 20px;
                    top: 50%;
                    margin-top: -1px;
                    left: 50%;
                    margin-left: -10px;
                    background: $white-color;

                    &:before {
                        content: "";
                        position: absolute;
                        height: 2px;
                        width: 20px;
                        top: 50%;
                        margin-top: -1px;
                        left: 50%;
                        margin-left: -10px;
                        background: $white-color;
                        transform: translateY(-5px);
                        transition: 0.3s ease-in-out;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        height: 2px;
                        width: 20px;
                        top: 50%;
                        margin-top: -1px;
                        left: 50%;
                        margin-left: -10px;
                        background: $white-color;
                        transform: translateY(5px);
                        transition: 0.3s ease-in-out;
                    }
                }

                &.active {
                    span {
                        border-radius: 50%;

                        &:before {
                            transform: rotate(-35deg);
                            width: 10px;
                            transform-origin: left bottom;
                            border-radius: 50%;
                        }

                        &:after {
                            transform: rotate(35deg);
                            width: 10px;
                            transform-origin: left top;
                            border-radius: 50%;
                        }
                    }

                    +.primary-menu-wrapper {
                        transform: translate(0, 100%);
                    }
                }

                @include tablet {
                    display: block;
                }
            }

            .mobile-logo {
                display: none;

                @include tablet {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 60px;
                    width: calc(100% - 120px);
                    height: 65px;
                    background-size: contain;

                    @include ico-bg(230px auto, center center);
                    @include tsn-mobile-ico(invert(100%) sepia(0%) saturate(3%) hue-rotate(20deg) brightness(103%) contrast(100%));

                    a {
                        display: inline-block;
                        width: 100%;
                        height: 65px;
                    }
                }

                @media screen and (max-width: 320px) {
                    @include ico-bg(200px auto, center center);
                }
            }

            .primary-menu-wrapper {
                &.editions {
                    @media screen and (min-width: 768px) {
                        display: none;
                    }
                }

                .desktop-primary-menu,
                .mobile-sidebar-menu {
                    >.menu {
                        display: flex;
                        margin: 0;
                        padding: 0;

                        >.menu-item {
                            font-size: rem(16px);
                            font-weight: bold;
                            list-style: none;

                            &:first-child {
                                > a {
                                    margin: 0 rem(10px) 0 0;

                                    @include tablet {
                                        padding: 0 rem(30px);
                                    }
                                }
                            }

                            &.more {
                                position: relative;
                                display: none;

                                >a {
                                    height: 100%;
                                    cursor: pointer;
                                }

                                >.more-submenu {
                                    visibility: hidden;
                                    position: absolute;
                                    display: block;
                                    padding: rem(40px) rem(10px) rem(10px) rem(10px);
                                    list-style: none;
                                    left: 50%;
                                    bottom: 0;
                                    transform: translate(-50%, 100%);
                                    background-color: $footer-bg;
                                    border-left: 1px solid $secondary-menu-border;
                                    border-right: 1px solid $secondary-menu-border;
                                    border-bottom: 2px solid $red-color;
                                    z-index: 10;

                                    &.two-columns {
                                        column-count: 2;
                                    }

                                    &.three-columns {
                                        column-count: 3;
                                    }

                                    &.four-columns {
                                        column-count: 4;
                                    }

                                    &:before {
                                        content: "";
                                        position: absolute;
                                        top: 2px;
                                        left: 50%;
                                        width: 0;
                                        height: 0;
                                        border-style: solid;
                                        border-width: 0 15px 15px;
                                        border-color: transparent transparent $footer-bg transparent;
                                        display: inline-block;
                                        vertical-align: middle;
                                        margin-right: rem(5px);
                                        transform: translate(-50%, -100%);
                                    }

                                    >.menu-item {
                                        position: relative;
                                        min-width: 150px;
                                        padding-left: rem(10px);
                                        margin-bottom: rem(30px);
                                        list-style: none;

                                        >a {
                                            font-size: rem(14px);
                                            color: $secondary-menu-color;
                                            text-decoration: none;
                                            padding-left: rem(20px);
                                            margin-left: -10px;
                                            line-height: initial;
                                            transition: color 0.03s linear;

                                            &:before {
                                                content: "";
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                height: 15px;
                                                width: 20px;
                                                background-repeat: no-repeat;

                                                @include ico-bg(10px auto, left center);
                                                @include right-chevron-ico(invert(25%) sepia(48%) saturate(5862%) hue-rotate(343deg) brightness(106%) contrast(87%));
                                            }

                                            &:hover {
                                                color: $red-color;
                                            }

                                            &:after {
                                                display: none;
                                            }

                                            @include desktop {
                                                font-size: rem(12px);
                                            }
                                        }

                                        >.menu {
                                            display: none;
                                        }

                                        >i.has-children__arrow {
                                            position: absolute;
                                            top: 3px;
                                            right: 5px;
                                            display: inline-block;
                                            width: 20px;
                                            height: 20px;
                                            transition: transform 0.3s linear;

                                            @include ico-bg(10px auto, center center);
                                            @include arrow-down-ico;
                                        }
                                    }
                                }

                                &.is-active {
                                    >.more-submenu {
                                        visibility: visible;
                                    }
                                }

                                &.has-items {
                                    display: block;

                                    .chevron {
                                        @include ico-bg(10px auto, left center);
                                        @include down-chevron-ico(invert(100%));

                                        filter: invert(54%) sepia(3%) saturate(0%) hue-rotate(169deg) brightness(88%) contrast(83%);
                                        display: inline-block;
                                        width: rem(20px);
                                        height: rem(12px);
                                        margin-left: rem(5px);

                                        &.not-hovered-menu {
                                            filter: invert(54%) sepia(3%) saturate(0%) hue-rotate(169deg) brightness(88%) contrast(83%);
                                        }
                                    }

                                    &.is-active {
                                        > a {
                                            color: $white-color !important;

                                            .chevron {
                                                filter: invert(100%);
                                            }
                                        }
                                    }

                                    &:hover {
                                        .chevron {
                                            filter: invert(100%);
                                        }
                                    }
                                }
                            }

                            a {
                                position: relative;
                                display: inline-block;
                                line-height: 50px;
                                text-decoration: none;
                                white-space: nowrap;
                                color: $white-color;
                                transition: color 0.03s linear;

                                &:after {
                                    content: "";
                                    position: absolute;
                                    height: 0;
                                    width: 0;
                                    bottom: 0;
                                    left: 0;
                                    transition: width 0.3s linear;
                                    box-sizing: border-box;
                                    border-bottom: 6px solid $red-color;
                                }

                                &:hover {
                                    @include tablet {
                                        color: $secondary-menu-color;
                                    }
                                }

                                @include desktop {
                                    font-size: rem(12px);
                                }
                            }

                            > a {
                                margin: 0 10px;

                                @include tablet {
                                    margin: 0;

                                    &:hover,
                                    &.is-active {
                                        border-bottom: none;
                                    }
                                }
                            }

                            &.menu-item--active-trail {
                                > a {
                                    &:after {
                                        content: "";
                                        height: 0;
                                        width: 100%;
                                        position: absolute;
                                        bottom: 0;
                                        left: 0;
                                        transition: all 0.3s ease;
                                        box-sizing: border-box;
                                        border-bottom: 6px solid $red-color;

                                        @include tablet {
                                            display: none;
                                        }
                                    }
                                }
                            }

                            &:not(.menu-item--active-trail) {
                                > .menu {
                                    display: none;

                                    @include tablet {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }

                .desktop-primary-menu {
                    >.menu {
                        >.menu-item {
                            &:hover {
                                > a {
                                    color: $white-color;

                                    &:after {
                                        content: "";
                                        position: absolute;
                                        height: 0;
                                        width: 100%;
                                        bottom: 0;
                                        left: 0;
                                        transition: width 0.3s ease;
                                        box-sizing: border-box;
                                        border-bottom: 6px solid $red-color;

                                        @include tablet {
                                            display: none;
                                        }
                                    }
                                }
                            }

                            &.menu-item--active-trail,
                            &.active-menu-item,
                            &.menu-item--active-trail:not(:hover) {
                                > a {
                                    color: $white-color;
                                }
                            }

                            &.menu-item--active-trail {
                                > a {
                                    color: $white-color;
                                }
                            }
                        }
                    }
                }

                .mobile-sidebar-menu {
                    display: none;
                }

                .editions-mobile {
                    display: none;
                }

                .tsn-account-menu-wrapper {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    z-index: 100;
                    height: fit-content;
                    position: absolute;
                    top: 0;
                    right: 0;

                    #block-tsn-account-menu {
                        position: relative;
                        padding-right: rem(20px);

                        &:after {
                            content: "";
                            position: absolute;
                            height: 100%;
                            width: 1px;
                            top: 0;
                            right: 0;
                            background-color: $primary-menu-delimiter;
                        }
                    }

                    #block-tsndomainnavigation {
                        .menu {
                            background-color: transparent;
                            font-size: rem(14px);
                            margin-top: rem(10px);
                            padding: 0;

                            .menu-item {
                                display: none;

                                &.active {
                                    display: list-item;
                                    text-align: right;
                                    margin-bottom: rem(15px);
                                    padding-top: 0.2em;
                                    padding-right: rem(21px);
                                    list-style: none outside;

                                    .title {
                                        font-weight: bold;

                                        @include desktop {
                                            font-size: rem(12px);
                                        }
                                    }
                                }
                            }

                            h3 {
                                display: none;
                            }

                            &.open {
                                &:before {
                                    border-width: 0 15px 15px;
                                    border-color: transparent transparent #f9f9f9;
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    width: 0;
                                    height: 0;
                                    border-style: solid;
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin-right: 0.31rem;
                                    transform: translate(-120%, 38px);
                                }

                                .menu-item {
                                    display: list-item;
                                    list-style: none outside;
                                }

                                .menu-items {
                                    background-color: #f9f9f9;
                                    border: 1px solid #d8d8d8;
                                    border-bottom: 2px solid $red-color;
                                    display: grid;
                                    grid-template-columns: repeat(3, auto);
                                    list-style: none outside;
                                    padding: rem(21px);
                                    gap: 0 37px;
                                    width: fit-content;
                                    font-weight: bold;

                                    h3 {
                                        grid-column: 1/-1;
                                        font-size: rem(13px);
                                        margin: 0;
                                        display: list-item;
                                    }

                                    >* {
                                        width: max-content;

                                        a {
                                            color: #231f20;
                                            display: flex;
                                            align-items: center;
                                            margin-top: 25px;
                                        }
                                    }
                                }

                                .link {
                                    .chevron {
                                        transform: rotate(180deg);
                                    }
                                }
                            }

                            .link {
                                color: $white-color;
                                font-size: rem(13px);
                                text-decoration: none;
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                                cursor: pointer;

                                @media screen and (max-width: 784px) {
                                    padding: 0;
                                }

                                .chevron {
                                    @include ico-bg(10px auto, left center);
                                    @include down-chevron-ico(invert(100%));

                                    display: inline-block;
                                    width: rem(10px);
                                    height: rem(10px);
                                    margin-left: rem(5px);
                                    transition: all 0.3s linear;
                                }

                                .flag {
                                    width: rem(20px);
                                    height: rem(20px);
                                    display: inline-block;
                                    margin-right: rem(5px);

                                    @include ico-bg(20px 20px, top);
                                }

                                .title {
                                    display: inline-block;
                                    line-height: rem(22px);
                                    vertical-align: top;
                                    font-size: rem(13px);
                                    font-weight: bold;
                                }

                                &.us,
                                &.us-es {
                                    .flag {
                                        @include usa-flag-ico;
                                    }
                                }

                                &.ca {
                                    .flag {
                                        @include canadian-flag-ico;
                                    }
                                }

                                &.au {
                                    .flag {
                                        @include australian-flag-ico;
                                    }
                                }

                                &.br {
                                    .flag {
                                        @include brazil-flag-ico;
                                    }
                                }

                                &.jp {
                                    .flag {
                                        @include japan-flag-ico;
                                    }
                                }

                                &.hk {
                                    .flag {
                                        @include hong-kong-flag-ico;
                                    }
                                }

                                &.ar {
                                    .flag {
                                        @include argentina-flag-ico;
                                    }
                                }

                                &.es {
                                    .flag {
                                        @include spain-flag-ico;
                                    }
                                }

                                &.in {
                                    .flag {
                                        @include india-flag-ico;
                                    }
                                }

                                &.mx {
                                    .flag {
                                        @include mexico-flag-ico;
                                    }
                                }

                                &.uk {
                                    .flag {
                                        @include united-kingdom-flag-ico;
                                    }
                                }

                                &.nz {
                                    .flag {
                                        @include new-zealand-flag-ico;
                                    }
                                }

                                &.sg {
                                    .flag {
                                        @include singapore-flag-ico;
                                    }
                                }

                                &.my {
                                    .flag {
                                        @include malaysia-flag-ico;
                                    }
                                }

                                &.th {
                                    .flag {
                                        @include thailand-flag-ico;
                                    }
                                }

                                &.kr {
                                    .flag {
                                        @include south-korea-flag-ico;

                                        background-size: 28px 20px;
                                    }
                                }

                                &.vn {
                                    .flag {
                                        @include vietnam-flag-ico;
                                    }
                                }
                            }
                        }
                    }

                    .menu-action {
                        display: flex;
                        position: absolute;
                        list-style-type: none;
                        align-items: center;
                        margin: 0;
                        padding: 0;
                        top: 0;
                        right: 100px;
                        border-right: 1px solid #d8d8d8;

                        li {
                            a {
                                color: $white-color;
                                text-decoration: none;
                                position: relative;
                                display: inline-block;
                                line-height: 50px;
                                font-weight: bold;
                                margin-right: rem(18px);
                            }
                        }

                        .search-logo {
                            @include search-ico;

                            background-size: contain;
                            width: 16px;
                            height: 16px;
                            background-repeat: no-repeat;
                            margin: 0 rem(20px) 0 rem(17px);
                        }

                        .user-logo {
                            @include user-ico;

                            background-size: contain;
                            width: 16px;
                            height: 16px;
                            background-repeat: no-repeat;
                            margin-right: rem(20px);
                        }
                    }

                    @include tablet {
                        display: none;
                    }
                }

                @include tablet {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: max-content;
                    overflow-y: auto;
                    background-color: $footer-bg;
                    transition: transform 0.3s ease-in-out;
                    transform: translate(-100%, 100%);
                    z-index: 100;

                    .desktop-primary-menu {
                        display: none;
                    }

                    .mobile-sidebar-menu {
                        display: block;

                        >.menu {
                            flex-direction: column;
                            height: max-content;
                            padding: rem(30px) 0;
                            background-color: $footer-bg;

                            >.menu-item {
                                position: relative;
                                margin: 0;

                                >.menu {
                                    position: absolute;
                                    left: 0;
                                    bottom: -50px;
                                    margin: 0;
                                    padding: 0;
                                    min-width: 600px;
                                    width: max-content;
                                    max-width: 1200px;
                                    display: none;

                                    /* TSN-1405 */
                                    @include tablet {
                                        .menu-item {
                                            width: auto;
                                            flex-grow: 1;
                                        }
                                    }

                                    .menu-item {
                                        height: 50px;
                                        padding-top: 0;
                                        font-size: rem(14px);
                                        margin-right: rem(21px);
                                        list-style: none;
                                        position: relative;

                                        @include tablet {
                                            margin-right: 0;
                                        }

                                        a {
                                            color: $secondary-menu-color;
                                            cursor: pointer;
                                        }

                                        @media screen and (min-width: 769px) {
                                            >.menu {
                                                position: absolute;
                                                visibility: hidden;
                                                display: block;
                                                padding: rem(40px) 0 rem(5px) rem(41px);
                                                list-style: none;
                                                left: 80px;
                                                bottom: 0;
                                                transform: translate(-50%, 100%);
                                                background-color: $footer-bg;
                                                border-top: 1px solid $secondary-menu-border;
                                                border-left: 1px solid $secondary-menu-border;
                                                border-right: 1px solid $secondary-menu-border;
                                                border-bottom: 2px solid $red-color;
                                                z-index: 10;

                                                &:before,
                                                &:after {
                                                    content: "";
                                                    position: absolute;
                                                    top: 0;
                                                    width: 0;
                                                    height: 0;
                                                    border-style: solid;
                                                    display: inline-block;
                                                    vertical-align: middle;
                                                    margin-right: rem(5px);
                                                    transform: translate(-50%, -100%);
                                                }

                                                &:before {
                                                    border-width: 0 15px 15px;
                                                    border-color: transparent transparent $secondary-menu-border transparent;
                                                }

                                                &:after {
                                                    border-width: 0 14px 14px;
                                                    border-color: transparent transparent $footer-bg transparent;
                                                }

                                                .menu-item {
                                                    position: relative;
                                                    min-width: 150px;
                                                    padding-left: rem(10px);
                                                    margin-bottom: rem(30px);
                                                    list-style: none;
                                                    height: auto;

                                                    a {
                                                        font-size: rem(14px);
                                                        color: $secondary-menu-color;
                                                        text-decoration: none;
                                                        padding-left: rem(20px);
                                                        margin-left: -10px;
                                                        line-height: initial;
                                                        transition: color 0.03s linear;

                                                        &:before {
                                                            content: "";
                                                            position: absolute;
                                                            top: 0;
                                                            left: 0;
                                                            height: 15px;
                                                            width: 20px;
                                                            background-repeat: no-repeat;

                                                            @include ico-bg(10px auto, left center);
                                                            @include right-chevron-ico(invert(25%) sepia(48%) saturate(5862%) hue-rotate(343deg) brightness(106%) contrast(87%));
                                                        }

                                                        &:hover {
                                                            color: $red-color;
                                                        }

                                                        &:after {
                                                            display: none;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    @include tablet {
                                        margin-right: 0;
                                        position: relative;
                                        display: block;
                                        overflow: hidden;
                                        max-height: 0;
                                        bottom: auto;
                                        left: auto;
                                        transition: all 0.3s cubic-bezier(0, 1.21, 1, 1);
                                        background-color: #ebebeb;
                                        padding: 0 rem(30px);
                                        box-sizing: border-box;
                                        width: auto;
                                        min-width: auto;

                                        >.menu-item {
                                            margin-right: 0;
                                            height: fit-content;

                                            a {
                                                padding-left: rem(15px);
                                                color: $secondary-menu-color;
                                                white-space: normal;
                                                line-height: 1.5rem;
                                                padding-top: 0.8rem;
                                                padding-bottom: 0.8rem;
                                                width: 100%;

                                                &:hover,
                                                &.is-active {
                                                    border-bottom: none;
                                                }
                                            }

                                            &:before {
                                                content: "";
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                height: 50px;
                                                width: 20px;
                                                background-repeat: no-repeat;

                                                @include ico-bg(10px auto, left center);
                                                @include right-chevron-ico(invert(25%) sepia(48%) saturate(5862%) hue-rotate(343deg) brightness(106%) contrast(87%));
                                            }
                                        }
                                    }
                                }

                                >i.has-children__arrow {
                                    position: absolute;
                                    top: 0;
                                    right: 30px;
                                    display: inline-block;
                                    width: 30px;
                                    height: 44px;
                                    transition: all 0.3s linear;

                                    @include ico-bg(10px auto, center center);
                                    @include arrow-down-ico;
                                }

                                >a {
                                    display: block;
                                    margin-right: rem(30px);
                                    padding: 0 rem(30px);
                                    color: $secondary-menu-color;
                                    font-size: 16px;
                                }

                                &.is-open-mobile {
                                    >.menu {
                                        max-height: fit-content;
                                        display: flex;
                                        flex-direction: column;
                                        padding-top: 10px;
                                        padding-bottom: 10px;
                                        grid-template-columns: 1fr;
                                    }

                                    .third-level-item {
                                        background-color: #e3e3e3;

                                        .menu-item {
                                            margin-left: 1rem;
                                        }
                                    }

                                    .has-children__arrow {
                                        transform: rotate(180deg);
                                    }

                                    .has-children__second-level {
                                        position: relative;
                                        order: 99 !important;
                                        width: auto;

                                        >i.has-children-second__arrow {
                                            position: absolute;
                                            top: 0;
                                            right: 0;
                                            display: inline-block;
                                            width: 30px;
                                            height: 44px;
                                            transition: all 0.2s linear;

                                            @include ico-bg(8px auto, center center);
                                            @include arrow-down-ico;
                                        }

                                        >.menu {
                                            @include tablet {
                                                /* TSN-1405 */
                                                display: inline-block;
                                                overflow: hidden;
                                                max-height: 0;
                                                bottom: auto;
                                                left: auto;
                                                transition: all 0.2s linear;
                                                background-color: #ebebeb;
                                                box-sizing: border-box;
                                                min-width: auto;

                                                >.menu-item {
                                                    visibility: hidden;

                                                    a {
                                                        padding-left: rem(15px);
                                                    }

                                                    &:before {
                                                        content: "";
                                                        position: absolute;
                                                        top: 0;
                                                        left: 0;
                                                        height: 50px;
                                                        width: 20px;
                                                        background-repeat: no-repeat;

                                                        @include ico-bg(10px auto, left center);
                                                        @include right-chevron-ico(invert(25%) sepia(48%) saturate(5862%) hue-rotate(343deg) brightness(106%) contrast(87%));
                                                    }
                                                }
                                            }
                                        }

                                        &.second-level-is-open-mobile {
                                            height: auto;
                                            width: auto;

                                            >i.has-children-second__arrow {
                                                transform: rotate(180deg);
                                            }

                                            >.menu {
                                                @include tablet {
                                                    max-height: 200px;
                                                    width: calc(100% + 80px) !important;

                                                    >.menu-item {
                                                        visibility: visible;

                                                        &:before {
                                                            content: "";
                                                            position: absolute;
                                                            top: 0;
                                                            left: 0;
                                                            height: 50px;
                                                            width: 20px;
                                                            background-repeat: no-repeat;

                                                            @include ico-bg(10px auto, left center);
                                                            @include right-chevron-ico(invert(25%) sepia(48%) saturate(5862%) hue-rotate(343deg) brightness(106%) contrast(87%));
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    @media (orientation: landscape) {
                                        >.menu {
                                            display: grid;
                                            grid-template-columns: 1fr 1fr 1fr;
                                            margin-left: 0;
                                        }
                                    }
                                }

                                &.menu-item--active-trail {
                                    >a {
                                        &:after {
                                            display: none;
                                        }
                                    }

                                    > .menu {
                                        display: grid;

                                        .more-second {
                                            position: relative;
                                            display: none;

                                            &.has-items {
                                                display: block;
                                            }
                                        }

                                        a {
                                            &:after {
                                                content: "";
                                                width: 100%;
                                                background: $red-color;
                                                position: absolute;
                                                bottom: 0;
                                                left: 0;
                                                transition: border-bottom 0.03s linear;
                                                box-sizing: border-box;
                                                border-bottom: 0 solid $red-color;

                                                @include tablet {
                                                    display: none;
                                                }
                                            }

                                            &.is-active {
                                                &:after {
                                                    border-bottom: 6px solid $red-color;
                                                }
                                            }
                                        }

                                        .menu-item--active-trail {
                                            a {
                                                &:after {
                                                    border-bottom: 6px solid $red-color;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .additional-menu-wrapper {
                display: flex;

                .additional-header--menu {
                    position: inherit;
                    display: flex;
                    height: rem(50px);

                    .menu-item {
                        font-size: 1rem;
                        margin: 0 1.25rem 0 0;
                        font-weight: bold;
                        padding-top: 0;
                        list-style: none;

                        a {
                            position: relative;
                            display: inline-block;
                            line-height: 50px;
                            text-decoration: none;
                            color: $white-color;
                            white-space: nowrap;
                            background-color: transparent;
                            transition: color 0.03s linear;

                            &:after {
                                content: "";
                                position: absolute;
                                height: 0;
                                width: 0;
                                bottom: 0;
                                left: 0;
                                transition: all 0.3s ease;
                                box-sizing: border-box;
                                border-bottom: 6px solid $red-color;
                            }

                            @include desktop {
                                font-size: rem(12px);
                            }
                        }

                        &.menu-item--active-trail,
                        &.active-menu-item {
                            > a {
                                color: $white-color;

                                &:after {
                                    content: "";
                                    height: 0;
                                    width: 100%;
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    transition: all 0.3s ease;
                                    box-sizing: border-box;
                                    border-bottom: 6px solid $red-color;

                                    @include tablet {
                                        display: none;
                                    }
                                }
                            }
                        }

                        &:hover {
                            > a {
                                &:after {
                                    content: "";
                                    height: 0;
                                    width: 100%;
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    transition: width 0.3s ease;
                                    box-sizing: border-box;
                                    border-bottom: 6px solid $red-color;
                                }
                            }
                        }
                    }

                    .menu-item--active-trail {
                        >a {
                            &:after {
                                width: 100%;
                            }
                        }
                    }
                }

                @include tablet {
                    position: absolute;
                    height: inherit;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    overflow-y: auto;
                    background-color: $aubergine-color;
                    transition: transform 0.3s ease-in-out;
                    transform: translate(-100%, 100%);
                    z-index: 100;

                    &.active {
                        transform: translate(0, 100%);
                    }

                    .additional-header--menu {
                        box-sizing: border-box;
                        margin: 0;
                        padding: 0 30px;
                        width: 100%;
                        border-right: none;
                        background: #221f20;
                        position: relative;
                        display: flex;
                        height: 50px;

                        a {
                            font-size: rem(14px);
                        }
                    }
                }
            }

            &.no-active-trail {
                .primary-menu-wrapper,
                .additional-menu-wrapper {
                    > .menu,
                    > .additional-header--menu {
                        .menu-item {
                            a {
                                color: $white-color;
                            }
                        }
                    }
                }
            }
        }

        @include tablet {
            padding: 0;

            .tsn-primary-menu__container {
                height: 65px;

                .primary-menu-wrapper {
                    .menu {
                        .menu-item {
                            visibility: visible;
                            width: initial;

                            &:hover > a,
                            &:not(:hover) > a,
                            > a {
                                color: $secondary-menu-color;
                            }

                            &.menu-item--active-trail,
                            &.menu-item--active-trail:not(:hover) {
                                > a,
                                .menu .menu-item--active-trail a {
                                    color: $secondary-menu-color;
                                }
                            }
                        }
                    }

                    .editions-mobile {
                        align-items: center;
                        z-index: 100;
                        background-color: #ebebeb;
                        text-align: center;
                        display: block;

                        #block-tsndomainnavigation > ul.menu li.menu-item.active {
                            display: inline-block !important;
                            width: 100%;
                            text-align: left;
                            margin: 0 rem(30px);
                            padding: 20px 0;
                            border-top: 1px solid #d8d8d8;

                            .link:after {
                                content: "Edition";
                                font-size: rem(14px);
                                color: #7d828e;
                                vertical-align: middle;
                            }
                        }

                        #block-tsndomainnavigation > ul.menu li.menu-item.active:after {
                            content: " ";
                            position: absolute;
                            right: 30px;
                            width: 30px;
                            height: 30px;
                            transition: all 0.3s linear;

                            @include ico-bg(10px auto, center center);
                            @include arrow-down-ico;
                        }

                        #block-tsndomainnavigation > ul.open li.active:after {
                            content: " ";
                            position: absolute;
                            right: 30px;
                            width: 30px;
                            height: 30px;

                            @include ico-bg(10px auto, center center);
                            @include arrow-down-ico;

                            transform: rotate(180deg) !important;
                        }

                        #block-tsndomainnavigation {
                            background-color: #f9f9f9;

                            .menu {
                                font-size: rem(14px);
                                display: flex !important;
                                flex-wrap: wrap;
                                padding: 0;
                                padding-top: rem(14px);
                                margin: 0;

                                .menu-item {
                                    display: none;
                                    flex-grow: 0 !important;
                                    min-width: 145px;

                                    &.active {
                                        display: list-item;
                                        text-align: right;
                                    }
                                }

                                &.open {
                                    .menu-item,
                                    >.menu-items h3 {
                                        display: list-item;
                                        list-style: none outside;
                                        text-align: left;
                                    }
                                }

                                >.menu-item {
                                    font-size: rem(16px);
                                    font-weight: bold;
                                    padding-top: 0;
                                    list-style: none;
                                    vertical-align: middle !important;

                                    .active {
                                        vertical-align: middle !important;
                                    }
                                }

                                >.menu-items {
                                    display: grid;
                                    grid-template-columns: repeat(auto-fit, minmax(148px, 1fr));
                                    width: 100%;
                                    padding: 0 rem(30px);

                                    h3 {
                                        display: none;
                                        grid-column: 1 / -1;
                                        line-height: 20px;
                                        font-size: rem(13px);
                                        font-weight: bold;
                                        color: #231f20;
                                        margin: rem(19.17px) 0 0;
                                    }

                                    >* {
                                        line-height: 60px;
                                    }

                                    .menu-item {
                                        &:last-child {
                                            padding-bottom: rem(20px);
                                        }
                                    }
                                }

                                .link {
                                    font-size: rem(14px);
                                    text-decoration: none;
                                    cursor: pointer;
                                    color: #231f20;

                                    @media screen and (max-width: 784px) {
                                        padding: 0;
                                    }

                                    .flag {
                                        width: rem(20px);
                                        height: rem(20px);
                                        display: inline-block;
                                        margin-right: rem(5px);

                                        @include ico-bg(20px 20px, top);

                                        vertical-align: middle;
                                    }

                                    .title {
                                        display: inline-block;
                                        line-height: rem(22px);
                                        vertical-align: middle;
                                        font-size: rem(13px);
                                        font-weight: bold;
                                    }

                                    &.us,
                                    &.us-es {
                                        .flag {
                                            @include usa-flag-ico;
                                        }
                                    }

                                    &.ca {
                                        .flag {
                                            @include canadian-flag-ico;
                                        }
                                    }

                                    &.au {
                                        .flag {
                                            @include australian-flag-ico;
                                        }
                                    }

                                    &.jp {
                                        .flag {
                                            @include japan-flag-ico;
                                        }
                                    }

                                    &.hk {
                                        .flag {
                                            @include hong-kong-flag-ico;
                                        }
                                    }

                                    &.ar {
                                        .flag {
                                            @include argentina-flag-ico;
                                        }
                                    }

                                    &.es {
                                        .flag {
                                            @include spain-flag-ico;
                                        }
                                    }

                                    &.in {
                                        .flag {
                                            @include india-flag-ico;
                                        }
                                    }

                                    &.mx {
                                        .flag {
                                            @include mexico-flag-ico;
                                        }
                                    }

                                    &.uk {
                                        .flag {
                                            @include united-kingdom-flag-ico;
                                        }
                                    }

                                    &.nz {
                                        .flag {
                                            @include new-zealand-flag-ico;
                                        }
                                    }

                                    &.sg {
                                        .flag {
                                            @include singapore-flag-ico;
                                        }
                                    }

                                    &.my {
                                        .flag {
                                            @include malaysia-flag-ico;
                                        }
                                    }

                                    &.th {
                                        .flag {
                                            @include thailand-flag-ico;
                                        }
                                    }

                                    &.kr {
                                        .flag {
                                            @include south-korea-flag-ico;

                                            background-size: 28px 20px;
                                        }
                                    }

                                    &.vn {
                                        .flag {
                                            @include vietnam-flag-ico;
                                        }
                                    }

                                    &.br {
                                        .flag {
                                            @include brazil-flag-ico;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .additional-menu-wrapper {
                    .additional-header--menu {
                        .menu-item {
                            &:hover > a,
                            &:not(:hover) > a,
                            > a {
                                color: $white-color;
                            }

                            &.menu-item--active-trail,
                            &.menu-item--active-trail:not(:hover) {
                                > a {
                                    color: $white-color;
                                }
                            }
                        }
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        top: rem(48px);
                        border-bottom: 2px solid $red-color;
                        width: 100%;
                        z-index: 1;
                    }
                }

                .mobile-menu-btn + .primary-menu-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }
        }
    }

    &__bottom {
        display: block;
        height: 50px;
        box-sizing: border-box;
        background-color: $footer-bg;
        top: 0;
        transition: top 0.4s ease-out;
        border-bottom: 1px solid $secondary-menu-border;
        padding: 0 10px;

        ul.sub-menu {
            display: none;
        }

        &:empty {
            display: block;
        }

        .desktop-secondary-menu,
        .mobile-secondary-menu {
            >.menu {
                max-width: 1100px;
                margin: 0 auto;
                position: relative;
                display: flex;
                height: 50px;
                padding: 0;

                >.menu-item {
                    font-size: rem(14px);
                    margin: 0 rem(27px) 0 0;
                    font-weight: bold;
                    padding-top: 0;
                    list-style: none;

                    >a {
                        position: relative;
                        display: inline-block;
                        line-height: 50px;
                        text-decoration: none;
                        color: $secondary-menu-color;
                        white-space: nowrap;

                        &:after {
                            content: "";
                            width: 100%;
                            background: $red-color;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            transition: all 0.03s linear;
                            box-sizing: border-box;
                            border-bottom: 0 solid $red-color;
                        }

                        &:hover,
                        &.is-active {
                            &:after {
                                @include tablet {
                                    border: unset;
                                }
                            }
                        }
                    }

                    >.menu {
                        display: none;
                    }

                    &.menu-item--active-trail {
                        >.menu {
                            display: flex;
                        }

                        >a {
                            &:after {
                                border-bottom: rem(6px) solid $red-color;
                            }

                            @include tablet {
                                border-bottom: none;
                            }
                        }
                    }

                    &.is-open-mobile {
                        @include tablet {
                            >.menu {
                                max-height: 400px;
                            }

                            .has-children__arrow {
                                transform: rotate(180deg);
                            }
                        }
                    }

                    &.more-second {
                        position: relative;
                        display: none;

                        a {
                            cursor: pointer;
                        }

                        &.has-items {
                            display: block;

                            > .more-submenu-second {
                                visibility: hidden;
                                position: absolute;
                                display: block;
                                padding: rem(40px) rem(10px) rem(10px) rem(10px);
                                list-style: none;
                                left: 50%;
                                bottom: 0;
                                transform: translate(-50%, 100%);
                                background-color: $footer-bg;
                                border-left: 1px solid $secondary-menu-border;
                                border-right: 1px solid $secondary-menu-border;
                                border-bottom: 2px solid $red-color;
                                z-index: 10;

                                &:before {
                                    content: "";
                                    position: absolute;
                                    top: 2px;
                                    left: 50%;
                                    width: 0;
                                    height: 0;
                                    border-style: solid;
                                    border-width: 0 15px 15px;
                                    border-color: transparent transparent $footer-bg transparent;
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin-right: rem(5px);
                                    transform: translate(-50%, -100%);
                                }

                                &.two-columns {
                                    column-count: 2;
                                }

                                &.three-columns {
                                    column-count: 3;
                                }

                                &.four-columns {
                                    column-count: 4;
                                }

                                >.menu-item {
                                    position: relative;
                                    min-width: 150px;
                                    padding-left: rem(10px);
                                    margin-bottom: rem(30px);
                                    list-style: none;
                                    height: inherit;

                                    >a {
                                        font-size: rem(14px);
                                        color: $secondary-menu-color;
                                        text-decoration: none;
                                        padding-left: rem(20px);
                                        margin-left: -10px;
                                        line-height: initial;
                                        transition: color 0.03s linear;

                                        &:before {
                                            content: "";
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            height: 15px;
                                            width: 20px;
                                            background-repeat: no-repeat;

                                            @include ico-bg(10px auto, left center);
                                            @include right-chevron-ico(invert(25%) sepia(48%) saturate(5862%) hue-rotate(343deg) brightness(106%) contrast(87%));
                                        }

                                        &:hover {
                                            color: $red-color;
                                        }

                                        &:after {
                                            display: none;
                                        }
                                    }

                                    >.menu {
                                        display: none;
                                    }

                                    >i.has-children__arrow {
                                        position: absolute;
                                        top: 3px;
                                        right: 5px;
                                        display: inline-block;
                                        width: 20px;
                                        height: 20px;
                                        transition: transform 0.3s linear;

                                        @include ico-bg(10px auto, center center);
                                        @include arrow-down-ico;
                                    }
                                }
                            }
                        }

                        &.is-active {
                            >.more-submenu-second {
                                visibility: visible;
                            }
                        }
                    }

                    @media screen and (max-width: 992px) {
                        font-size: rem(12px);
                    }
                }
            }

            .nba-menu-wrapper {
                display: flex;
                justify-content: space-between;
                max-width: 1100px;
                margin: 0 auto;

                .nba-menu-inner {
                    display: flex;

                    .nba-menu-logo {
                        display: flex;

                        .logo {
                            width: 50px;
                            display: flex;
                        }
                    }

                    >.menu {
                        display: flex;
                        margin: 0;
                        padding-left: 16px;

                        >.menu-item {
                            display: flex;
                            align-items: center;
                            height: 50px;
                            padding-top: 0;
                            margin-right: 21px;
                            list-style: none;
                            position: relative;

                            a {
                                font-size: rem(14px);
                                font-weight: bold;
                                text-decoration: none;
                                color: $secondary-menu-color;
                                position: relative;
                                display: inline-block;
                                line-height: 50px;
                                white-space: nowrap;
                                cursor: pointer;

                                &:after {
                                    content: "";
                                    width: 100%;
                                    background: $red-color;
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    transition: all 0.03s linear;
                                    box-sizing: border-box;
                                    border-bottom: 0 solid $red-color;
                                }

                                &:hover,
                                &.is-active {
                                    &:after {
                                        @include tablet {
                                            border: unset;
                                        }
                                    }
                                }

                                @include desktop {
                                    font-size: rem(12px);
                                }
                            }

                            &.menu-item--active-trail {
                                >a {
                                    &:after {
                                        border-bottom: rem(6px) solid $red-color;
                                    }

                                    @include tablet {
                                        border-bottom: none;
                                    }
                                }
                            }

                            &.more-second {
                                position: relative;
                                display: none;

                                > .more-submenu-second {
                                    visibility: hidden;
                                    position: absolute;
                                    display: block;
                                    padding: rem(40px) rem(10px) rem(10px) rem(10px);
                                    list-style: none;
                                    left: 50%;
                                    bottom: 0;
                                    transform: translate(-50%, 100%);
                                    background-color: $footer-bg;
                                    border-left: 1px solid $secondary-menu-border;
                                    border-right: 1px solid $secondary-menu-border;
                                    border-bottom: 2px solid $red-color;
                                    z-index: 10;

                                    &:before {
                                        content: "";
                                        position: absolute;
                                        top: 2px;
                                        left: 50%;
                                        width: 0;
                                        height: 0;
                                        border-style: solid;
                                        border-width: 0 15px 15px;
                                        border-color: transparent transparent $footer-bg transparent;
                                        display: inline-block;
                                        vertical-align: middle;
                                        margin-right: rem(5px);
                                        transform: translate(-50%, -100%);
                                    }

                                    &.two-columns {
                                        column-count: 2;
                                    }

                                    &.three-columns {
                                        column-count: 3;
                                    }

                                    &.four-columns {
                                        column-count: 4;
                                    }

                                    >.menu-item {
                                        position: relative;
                                        min-width: 150px;
                                        padding-left: rem(10px);
                                        margin-bottom: rem(30px);
                                        list-style: none;
                                        height: inherit;

                                        >a {
                                            font-size: rem(14px);
                                            color: $secondary-menu-color;
                                            text-decoration: none;
                                            padding-left: rem(20px);
                                            margin-left: -10px;
                                            line-height: initial;
                                            transition: color 0.03s linear;

                                            &:before {
                                                content: "";
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                height: 15px;
                                                width: 20px;
                                                background-repeat: no-repeat;

                                                @include ico-bg(10px auto, left center);
                                                @include right-chevron-ico(invert(25%) sepia(48%) saturate(5862%) hue-rotate(343deg) brightness(106%) contrast(87%));
                                            }

                                            &:hover {
                                                color: $red-color;
                                            }

                                            &:after {
                                                display: none;
                                            }

                                            @include desktop {
                                                font-size: rem(12px);
                                            }
                                        }

                                        >.menu {
                                            display: none;
                                        }

                                        >i.has-children__arrow {
                                            position: absolute;
                                            top: 3px;
                                            right: 5px;
                                            display: inline-block;
                                            width: 20px;
                                            height: 20px;
                                            transition: transform 0.3s linear;

                                            @include ico-bg(10px auto, center center);
                                            @include arrow-down-ico;
                                        }
                                    }
                                }

                                &.has-items {
                                    display: block;
                                }

                                &.is-active {
                                    >.more-submenu-second {
                                        visibility: visible;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .mobile-secondary-menu {
            display: none;
        }

        .desktop-secondary-menu.max-w-default {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: auto;
            max-width: 1100px;

            > .menu {
                margin: unset;
            }

            .bg-desktop-nosso-futebol-assistir {
                display: block;

                @include desktop-nosso-futebol-assistir;

                @include tablet {
                    display: none;
                }
            }

            @include tablet {
                display: none;
            }
        }

        @include tablet {
            display: block;
            padding: 0;
            background-color: #f9f9f9;

            .desktop-secondary-menu,
            .mobile-secondary-menu {
                >.menu {
                    padding-left: 20px;
                    overflow: auto;

                    .menu-item {
                        &.menu-item--active-trail {
                            >a {
                                &:after {
                                    content: "";
                                    display: inline-block;
                                    height: 6px;
                                    width: 100%;
                                    background: $red-color;
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    transition: all 0.3s linear;
                                    box-sizing: border-box;
                                }
                            }
                        }
                    }
                }
            }

            .desktop-secondary-menu {
                display: none;
            }

            .mobile-secondary-menu {
                display: block;
            }

            &.is-nba,
            &.is-secondary {
                position: relative;
                display: block;

                >.menu {
                    padding: 0 15px;
                    overflow-x: scroll;

                    a {
                        &:after {
                            display: none;
                        }
                    }
                }
            }

            &.is-secondary {
                ul.sub-menu {
                    padding: 0 15px;
                    overflow-x: scroll;
                    max-width: 1100px;
                    margin: 0 auto;
                    position: relative;
                    display: flex;
                    height: 50px;
                    text-decoration: none;

                    li.menu-item.menu-item--expanded {
                        margin: 0 rem(27px) 0 0;
                        font-size: 14px;
                        font-weight: bold;
                        padding-top: 0;
                        list-style: none;

                        a {
                            position: relative;
                            display: inline-block;
                            line-height: 50px;
                            text-decoration: none;
                            color: #231f20;
                            white-space: nowrap;
                        }

                        &.menu-item--active-trail {
                            @media screen and (max-width: 768px) {
                                border-bottom: 6px solid #ef3340;
                            }
                        }
                    }
                }
            }
        }

        &.secondary-empty {
            display: none;
        }

        &.secondary-nba {
            display: block;
        }
    }

    &__fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        transition: transform 0.5s linear;

        @include tablet {
            position: unset;
        }
    }

    @include tablet {
        &.with-global {
            position: static;

            .tsn-primary-menu__top {
                width: 100%;
                z-index: 11;
                transition: none;
            }

            .tsn-primary-menu__bottom {
                .menu {
                    z-index: 10;
                    background-color: #f9f9f9;
                    border-bottom: 1px solid $secondary-menu-border;
                }

                &.overlay {
                    .menu {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

.watch-league-pass {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;

    a {
        display: flex !important;
        justify-content: center;
        border-radius: 5px;
        height: 40px;
        width: 247px;
        background: linear-gradient(90deg, rgb(29 66 138) 0%, rgb(29 66 138) calc(87%), rgb(202 26 53) calc(23%), rgb(202 26 53) 100%);
        background-size: 100% 100%;

        &:hover {
            &:after {
                display: none;
            }
        }

        @include tablet {
            width: 100%;
            border-radius: unset;
            height: 60px;
            background: linear-gradient(90deg, rgb(29 66 138) 0%, rgb(29 66 138) calc(50% + 135.47px), rgb(202 26 53) calc(50% + 135.47px), rgb(202 26 53) 100%);

            svg {
                height: 60px;
            }
        }
    }
}

.layout-container {
    main {
        > .watch-league-pass {
            display: none;

            @include tablet {
                display: flex;
            }
        }
    }

    .nba-experience-container {
        display: block;

        &.nba-experience-mobile {
            display: none;

            @include tablet {
                display: block;
            }
        }

        @include tablet {
            display: none;
        }
    }

    .news-page {
        .nba-experience-container {
            &.nba-experience-mobile {
                margin-top: 20px;
            }
        }
    }
}

.nba-experience-container {
    border-radius: 10px;
    border: solid 1px #e1e7ee;
    background: linear-gradient(0deg, rgb(225 231 238) 0%, rgb(255 255 255) 50%, rgb(255 255 255) 100%);
    margin-bottom: 20px;

    .nba-experience-header {
        display: flex;
        border-radius: 5px 5px 0 0;
        background: linear-gradient(90deg, rgb(29 66 138) 0%, rgb(29 66 138) calc(100% - 40px), rgb(202 26 53) calc(40px), rgb(202 26 53) 100%);
        background-size: 100% 100%;
        position: relative;
        height: 60px;

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            width: auto;

            &:first-child {
                left: 15px;
            }

            &:last-child {
                right: 15px;
            }
        }
    }

    .nba-logos-wrapper {
        padding: 10px;

        .nba-logos {
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: 100%;

            & > * {
                @include nba-logo-bg;

                height: 35px;
                border-radius: 5px;
                border: solid 1px #e1e7ee;
                display: flex;
                justify-content: center;
                align-items: center;

                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    width: 100%;

                    img {
                        width: auto;
                    }
                }
            }

            .league-pass {
                img {
                    margin-top: 3px;
                }
            }
        }
    }
}
